import { FC } from "react";

import { Typography } from "@mui/material";

import { styles } from "./styles";

import { Link } from "@/shared/ui";

type TProps = {
  name: string;
  link: string;
  isUnderlined?: boolean;
};

export const LinkItem: FC<TProps> = ({ name, link, isUnderlined }) => {
  return (
    <Link href={link}>
      <Typography component="span" sx={styles.linkItem(isUnderlined)}>
        {name}
      </Typography>
    </Link>
  );
};
