import { FC, memo, FormEvent } from "react";

import { Box, Alert, Typography } from "@mui/material";
import { useForm } from "effector-forms";
import { useUnit } from "effector-react";
import { useTranslations } from "next-intl";

import { $$registerUserForm } from "../../model/forms";
import { styles } from "./styles";

import { ModalLayout } from "@/features/login/ui/ModalLayout";

import { sendDataLayerEvent } from "@/shared/model/analytics";
import { signUpMutation } from "@/shared/model/api/auth";
import { NewInput, LoaderButton } from "@/shared/ui";

import InfoIcon from "@/public/icons/common/info.svg";

export const SignUp: FC = memo(() => {
  const t = useTranslations();

  const sendDataLayerEventFn = useUnit(sendDataLayerEvent);
  const { pending: isSignUpLoading } = useUnit(signUpMutation);
  const { fields, submit, errorText } = useForm($$registerUserForm);

  const handleSubmit = (event: FormEvent) => {
    event.preventDefault();
    submit();
    sendDataLayerEventFn({
      event: "signUpSuccess",
    });
  };

  return (
    <Box sx={styles.loginWrapper}>
      <form onSubmit={handleSubmit}>
        <ModalLayout
          Footer={
            <LoaderButton
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              sx={styles.submitButton}
              loading={isSignUpLoading}
            >
              {t("SAVE")}
            </LoaderButton>
          }
          Content={
            <Box sx={styles.contentWrapper}>
              <Box>
                <Alert
                  severity="info"
                  sx={styles.info}
                  variant="outlined"
                  icon={<InfoIcon />}
                >
                  <Typography sx={styles.infoText}>
                    {t(
                      "PLEASE_PROVIDE_YOUR_DETAILS_THIS_IS_REQUIRED_FOR_TOUR_PURCHASE",
                    )}
                  </Typography>
                </Alert>

                <Box sx={styles.loginFields}>
                  <NewInput
                    label={t("NAME")}
                    placeholder="Arman"
                    value={fields.first_name.value}
                    id="registration_name_field_test"
                    errorMessage={t(errorText("first_name"))}
                    onChange={(e) => fields.first_name.onChange(e.target.value)}
                  />
                  <NewInput
                    label={t("SURNAME")}
                    placeholder="Armanov"
                    value={fields.last_name.value}
                    id="registration_surname_field_test"
                    errorMessage={t(errorText("last_name"))}
                    onChange={(e) => fields.last_name.onChange(e.target.value)}
                  />
                  <NewInput
                    label="Email"
                    value={fields.email.value}
                    placeholder="email@example.com"
                    id="registration_email_field_test"
                    errorMessage={t(errorText("email"))}
                    onChange={(e) => fields.email.onChange(e.target.value)}
                  />
                </Box>
              </Box>
            </Box>
          }
        />
      </form>
    </Box>
  );
});
