export const styles = {
  darkkNavItem: {
    color: "grey.dark",
  },

  listItemActive: {
    textDecoration: "underline",
    textDecorationThickness: "1px",
  },

  listItem: {
    fontSize: "16px",
    cursor: "pointer",
    transition: "0.2s",

    "&:hover": {
      opacity: 0.7,
    },
  },

  whiteNavItem: {
    gap: "4px",
    display: "flex",
    alignItems: "center",
    textDecoration: "none",
    color: "dominant.white",
  },

  headerNavItem: {
    padding: 0,
    display: "flex",
    columnGap: "8px",
    fontSize: "14px",
    cursor: "pointer",
    lineHeight: "16px",
    transition: "none",
    alignItems: "center",
  },
};
