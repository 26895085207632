export const styles = {
  logo: {
    width: "100%",
    maxWidth: "183px",
  },

  questionnaire: {
    padding: 0,
    fontSize: "16px",
  },

  container: {
    zIndex: 5,
    color: "white",
    position: "relative",
  },

  navigation: {
    display: "flex",
    columnGap: "32px",
    alignItems: "center",
  },

  button: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },

  navlist: {
    margin: 0,
    padding: 0,
    display: "flex",
    columnGap: "24px",
    alignItems: "center",
    listStyleType: "none",
  },
};
