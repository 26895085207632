import { FC, memo } from "react";

import { Box } from "@mui/joy";

import { styles } from "./styles";

type TProps = {
  isDefault?: boolean;
  children: JSX.Element;
};

export const MenuWrapper: FC<TProps> = memo(({ children, isDefault }) => {
  return (
    <Box
      component="header"
      sx={isDefault ? styles.default : styles.transparent}
    >
      {children}
    </Box>
  );
});
