import { createApi, createStore } from "effector";

export const $anchorEl = createStore<null | HTMLElement>(null);

export const $isOpen = $anchorEl.map((anchorEl) => Boolean(anchorEl));

export const { setAnchorEl, resetAnchorEl } = createApi($anchorEl, {
  resetAnchorEl: () => null,
  setAnchorEl: (_, payload: HTMLElement) => payload,
});
