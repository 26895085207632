import { FC } from "react";

import { Box } from "@mui/material";

import { SocialItem } from "./SocialItem";
import { styles } from "./styles";

import { TContact, TBackendWA } from "@/shared/model/types/contacts";

type TProps = {
  socials: (TContact & TBackendWA)[];
};

export const SocialLinks: FC<TProps> = ({ socials }) => {
  return (
    <Box component="ul" sx={styles.wrapper}>
      {socials?.map(({ id, type, value }) => (
        <li key={id}>
          <SocialItem id={id} type={type} value={value} />
        </li>
      ))}
    </Box>
  );
};
