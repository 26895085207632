import { FC, useState } from "react";
import Countdown, { CountdownTimeDelta } from "react-countdown";

import {
  Box,
  Alert,
  Collapse,
  AlertColor,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useForm } from "effector-forms";
import { useUnit } from "effector-react";
import { useTranslations } from "next-intl";

import {
  $otpTime,
  resendOTP,
  $otpIndex,
  $currentPhone,
  returnToPhoneStep,
} from "../../model";
import { $$otpUserForm } from "../../model/forms";
import { ModalLayout } from "../ModalLayout";
import { styles } from "./styles";

import { Condition } from "@/shared/lib/condition";
import { useCustomTheme } from "@/shared/lib/hooks";
import { sendOTPMutation, confirmOTPMutation } from "@/shared/model/api/auth";
import { openNotification } from "@/shared/model/store/notifications";
import { OtpInput, LoaderButton } from "@/shared/ui";

export const OTP: FC = () => {
  const theme = useCustomTheme();
  const isPhone = useMediaQuery(theme.breakpoints.down("smd"));
  const t = useTranslations();

  const { pending: confirmOTPPending } = useUnit(confirmOTPMutation);
  const { pending: sendOTPPending } = useUnit(sendOTPMutation);

  const [currentTime, currentTimeIndex] = useUnit([$otpTime, $otpIndex]);
  const [resentOTPCode, currentPhone, onBack] = useUnit([
    resendOTP,
    $currentPhone,
    returnToPhoneStep,
  ]);

  const setNotification = useUnit(openNotification);
  const { reset, fields, submit } = useForm($$otpUserForm);

  const [requestMobileError, setRequestMobileError] = useState<null | {
    text: string;
    type: AlertColor;
  }>(null);

  const setError = (type: AlertColor, text: string) => {
    if (isPhone) {
      setRequestMobileError({
        type,
        text,
      });
    } else {
      setNotification({ message: text, severity: type });
    }
  };

  const handleSubmit = () => {
    submit();
    reset();
  };

  const submitCountdownRenderer = ({
    minutes,
    seconds,
    completed,
  }: CountdownTimeDelta) => {
    if (completed) {
      return (
        <LoaderButton
          fullWidth
          size="large"
          type="submit"
          variant="contained"
          onClick={resentOTPCode}
          sx={styles.submitButton}
          loading={confirmOTPPending}
        >
          {t("RESEND_CODE")}
        </LoaderButton>
      );
    } else {
      return (
        <LoaderButton
          disabled
          fullWidth
          size="large"
          loading={false}
          variant="contained"
          sx={styles.submitButton}
        >
          {t("RESEND_CODE_IN", {
            time: `${minutes}:
          ${seconds < 10 ? `0${seconds}` : seconds}`,
          })}
        </LoaderButton>
      );
    }
  };

  const changeCountdownRenderer = ({ completed }: CountdownTimeDelta) => {
    if (completed) {
      return (
        <Box onClick={onBack} sx={styles.changeNumberButton}>
          {t("CHANGE")}
        </Box>
      );
    } else {
      return (
        <Box
          sx={styles.changeNumberButton}
          onClick={() => {
            setError("info", "TO_CHANGE_NUMBER_WAIT_FOR_TIMER_END");
          }}
        >
          {t("CHANGE")}
        </Box>
      );
    }
  };

  return (
    <Box sx={styles.loginWrapper}>
      <ModalLayout
        Footer={
          <Countdown
            date={currentTime}
            key={currentTimeIndex}
            renderer={submitCountdownRenderer}
          />
        }
        Content={
          <Box sx={styles.contentWrapper}>
            <Box>
              <Box sx={styles.titleWrapper}>
                <Typography>{t("CODE_SENT_TO_NUMBER")}:</Typography>

                <Box sx={styles.phoneNumberWrapper}>
                  <Typography sx={styles.phoneNumber}>
                    {currentPhone}
                  </Typography>

                  <Countdown
                    date={currentTime}
                    key={currentTimeIndex}
                    renderer={changeCountdownRenderer}
                  />
                </Box>
              </Box>
              <Box sx={styles.inputWrapper}>
                <Typography sx={styles.inputLabel}>
                  {t("ENTER_CODE_FROM_SMS")}
                </Typography>

                <OtpInput
                  autoFocus
                  value={fields.code.value}
                  onComplete={handleSubmit}
                  id="authorization_otp_input_test"
                  onChange={(e) => fields.code.onChange(e)}
                  disabled={confirmOTPPending || sendOTPPending}
                />
              </Box>
            </Box>

            <Condition match={isPhone}>
              <Collapse in={!!requestMobileError}>
                <Alert
                  sx={styles.alert}
                  onClose={() => setRequestMobileError(null)}
                  severity={requestMobileError?.type ?? "error"}
                >
                  {
                    // eslint-disable-next-line @typescript-eslint/non-nullable-type-assertion-style
                    t(requestMobileError?.text)
                  }
                </Alert>
              </Collapse>
            </Condition>
          </Box>
        }
      />
    </Box>
  );
};
