import { FC } from "react";

import { Box, Divider, useMediaQuery } from "@mui/material";

import { styles } from "./styles";

import { Condition } from "@/shared/lib/condition";
import { useCustomTheme } from "@/shared/lib/hooks";

type TProps = {
  Footer: string | JSX.Element | JSX.Element[];
  Content: string | JSX.Element | JSX.Element[];
};

export const ModalLayout: FC<TProps> = ({ Footer, Content }) => {
  const theme = useCustomTheme();
  const isPhone = useMediaQuery(theme.breakpoints.down("smd"));

  return (
    <Box sx={styles.wrapper}>
      <Box sx={styles.content}>{Content}</Box>
      <Box>
        <Condition match={isPhone}>
          <Divider sx={styles.divider} />
        </Condition>

        <Box sx={styles.footer}>{Footer}</Box>
      </Box>
    </Box>
  );
};
