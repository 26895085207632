"use client";
import dynamic from "next/dynamic";
import { usePathname } from "next/navigation";
import { FC, memo } from "react";

import { useMediaQuery } from "@mui/material";

import { AuthStepper } from "@/features/login";
import { DesktopMenu } from "@/features/navbar/ui/Desktop";
import { MobileMenu } from "@/features/navbar/ui/Mobile";

import { DepartureList } from "@/entities/header";

import { Condition } from "@/shared/lib/condition";
import { useDayjsLocale } from "@/shared/lib/hooks";
import { useCustomTheme } from "@/shared/lib/hooks/useCustomTheme";
import { LoaderButton } from "@/shared/ui";

const HeaderActions = dynamic(
  () => import("@/features/header/HeaderActions/ui/Actions/Actions"),
  {
    ssr: false,
    loading: () => <LoaderButton loading color="secondary" />,
  },
);

export const Navbar: FC = memo(() => {
  const theme = useCustomTheme();
  const isPhone = useMediaQuery(theme.breakpoints.down("lg"));

  const pathname = usePathname();
  const isHomePage = ["/", "/kz"].includes(pathname);

  useDayjsLocale();

  return (
    <>
      {/* TODO: return when the app download popup is deleted  */}
      {/* <Condition match={isHomePage && isPhone}>
        <MobileDownloadAppBlock />
      </Condition> */}

      <Condition match={!isPhone}>
        <DesktopMenu
          isDefault={!isHomePage}
          HeaderActions={<HeaderActions isDefault={!isHomePage} />}
        />
      </Condition>

      <Condition match={isPhone}>
        <MobileMenu isDefault={!isHomePage} />
      </Condition>

      <AuthStepper />
      <DepartureList isModal />
    </>
  );
});
