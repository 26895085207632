"use client";
import { reflect } from "@effector/reflect";

import { SocialLinks } from "./SocialLinks";

import { SOCIAL_LINK_OPTIONS } from "@/entities/footer/lib";
import { $contactsData } from "@/entities/footer/model";

export const SocialLinksView = reflect({
  view: SocialLinks,
  bind: {
    socials: $contactsData.map((contacts) =>
      contacts.filter((c) => SOCIAL_LINK_OPTIONS.includes(c.type)),
    ),
  },
});
