import { FC, useState, MouseEvent } from "react";

import { Menu, MenuItem } from "@mui/material";
import { useLocale, useTranslations } from "next-intl";

import { styles } from "./styles";

import { LANG_OPTIONS } from "@/features/navbar/lib/constants";

import { useSwitchLocale } from "@/shared/lib/hooks";
import { CollapseButton } from "@/shared/ui";

type TProps = {
  isDefaultColor?: boolean;
};

export const LangSwitcher: FC<TProps> = ({ isDefaultColor = false }) => {
  const t = useTranslations();
  const locale = useLocale();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { onLangChange } = useSwitchLocale();

  const open = Boolean(anchorEl);

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <CollapseButton
        isOpen={open}
        id="basic-button"
        aria-haspopup="true"
        onClick={handleClick}
        aria-expanded={open ? "true" : undefined}
        aria-controls={open ? "basic-menu" : undefined}
        sx={{
          ...styles.button,
          color: isDefaultColor ? "content.primary" : "dominant.white",
        }}
      >
        {t("LANG_CODE")}
      </CollapseButton>

      <Menu
        open={open}
        id="basic-menu"
        anchorEl={anchorEl}
        onClose={() => handleClose()}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        {LANG_OPTIONS.map(({ code, name }) => (
          <MenuItem
            key={code}
            onClick={() => onLangChange()}
            sx={{
              ...styles.item,
              backgroundColor: code === locale ? "content.grey-100" : "unset",
            }}
          >
            {name}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};
