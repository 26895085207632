import { styled } from "@mui/system";

import { mainTheme } from "@/shared/ui/styles/theme/mainTheme";

export const List = styled("ul")`
  margin: 16px 0 0;
  padding: 0;
  list-style-type: none;
  display: flex;
  flex-direction: column;
  row-gap: 12px;
`;

export const ColumnTitle = styled("p")`
  font-size: 20px;
  font-weight: 500;
  margin: 0;
`;

export const styles = {
  link: {
    textDecoration: "none",
  },

  socialsIcon: {
    width: "16px",
    height: "16px",
  },

  callCenterSchedule: { lineHeight: 1.5, color: "content.grey-400" },
  callCenterTitle: { fontSize: 20, fontWeight: 500, lineHeight: "24px" },

  helpIcon: {
    width: "18px",
    height: "18px",
    fill: "transparent",
  },

  callCenter: {
    rowGap: "16px",
    display: "flex",
    flexDirection: "column",
  },

  helpLinkText: {
    columnGap: "6px",
    alignItems: "center",
    display: "inline-flex",
  },

  callCenterSocial: {
    fontSize: "14px",
    lineHeight: "16px",
    color: "primary.main",
  },

  paymentLogosWrapper: {
    height: "41px",
    display: "flex",
    columnGap: "12px",
    alignItems: "center",
  },

  policy: {
    width: "95%",
    [mainTheme.breakpoints.up("sm")]: {
      mt: "36px",
      width: "100%",
    },
  },

  lightGrayTitle: {
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "18px",
    marginBottom: "12px",
    color: "content.grey-400",
  },

  linkItem: (isUnderlined?: boolean) => ({
    cursor: "pointer",
    color: "content.secondary",
    textDecoration: isUnderlined ? "underline" : "none",
  }),

  paymentSystemsBlock: {
    display: "flex",
    alignItems: "flex-end",
    flexDirection: "column",
    [mainTheme.breakpoints.down("md")]: {
      alignItems: "flex-start",
    },
  },

  astanaHubBlock: {
    display: "flex",
    alignItems: "end",
    flexDirection: "column",
    icon: {
      opacity: 1,
      transition: "opacity .2s",
      "&:hover": {
        opacity: 0.7,
      },
    },
  },

  footer: {
    padding: "48px 0",
    marginTop: "96px",
    borderRadius: "16px 16px 0px 0px",
    backgroundColor: "primary.whiteblue",
    [mainTheme.breakpoints.down("md")]: {
      marginTop: "48px",
      padding: "24px 0",
    },
  },

  footerTop: {
    display: "flex",
    columnGap: "24px",
    flexDirection: "row",
    marginBottom: "21px",
    justifyContent: "space-between",

    [mainTheme.breakpoints.down("md")]: {
      rowGap: "32px",
      flexDirection: "column",
    },
  },

  socialBlock: {
    display: "flex",
    alignItems: "end",
    minWidth: "216px",
    flexDirection: "column",
    gridArea: "1 / 3 / 2 / 4",

    [mainTheme.breakpoints.down("smd")]: {
      alignItems: "start",
      gridArea: "3 / 1 / 4 / 2",
    },
  },

  footerBottom: {
    gap: "12px",
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    flexDirection: "row",
    color: "content.secondary",

    [mainTheme.breakpoints.down("sm")]: {
      flexDirection: "column",
      alignItems: "flex-start",
    },
  },

  navAbout: {
    paddingRight: "20px",
    gridArea: "1 / 2 / 3 / 3",
    [mainTheme.breakpoints.down("smd")]: {
      gridArea: "2 / 1 / 3 / 3",
    },
    item: {
      fontWeight: 400,
      fontSize: "16px",
      lineHeight: "18px",
      color: "content.secondary",
    },
  },

  navForCustomers: {
    paddingRight: "10px",
    gridArea: "1 / 1 / 3 / 2",
    [mainTheme.breakpoints.down("smd")]: {
      gridArea: "1 / 1 / 2 / 2",
    },
    item: {
      fontWeight: 400,
      fontSize: "16px",
      cursor: "pointer",
      lineHeight: "18px",
      color: "content.secondary",
    },
  },

  navigation: {
    rowGap: "32px",
    display: "grid",
    columnGap: "24px",

    gridTemplateRows: "repeat(3, min-content)",
    gridTemplateColumns: "minmax(184px, auto) minmax(184px, auto) auto",

    [mainTheme.breakpoints.down("smd")]: {
      columnGap: 0,
      rowGap: "36px",
      gridTemplateColumns: "minmax(184px, auto) 0",
    },
  },

  callCenterApps: {
    display: "block",
    marginTop: "32px",
    [mainTheme.breakpoints.down("smd")]: {
      marginTop: "24px",
    },

    text: {
      fontSize: "20px",
      fontWeight: "500",
      lineHeight: "24px",
      marginBottom: "16px",
    },

    appLinks: {
      gap: "16px",
      display: "flex",
      flexWrap: "wrap",

      "& a": {
        color: "grey.dark",
      },
    },
  },

  paymentAndAstanaHubBlock: {
    rowGap: "24px",
    display: "flex",
    flexDirection: "column",
    gridArea: "2 / 3 / 3 / 4",
    justifyContent: "flex-end",

    // eslint-disable-next-line perfectionist/sort-objects
    [mainTheme.breakpoints.down("md")]: {
      gridArea: "3 / 1 / 4 / 4",
      flexDirection: "row-reverse",
      justifyContent: "space-between",
    },
    // eslint-disable-next-line perfectionist/sort-objects
    [mainTheme.breakpoints.down("smd")]: {
      gridArea: "4 / 1 / 5 / 2",
    },
  },
};
