import { usePathname } from "next/navigation";
import { FC } from "react";

import { Box } from "@mui/material";

import { styles } from "./styles";

import { Link } from "@/shared/ui";

type TProps = {
  id?: string;
  href: string;
  title: string;
  isDefault?: boolean;
  onClick: () => void;
};

export const MenuLink: FC<TProps> = ({
  id,
  href,
  title,
  onClick,
  isDefault,
}) => {
  const pathname = usePathname();

  return (
    <Link id={id} href={href} onClick={onClick}>
      <Box
        component="li"
        sx={[
          styles.listItem,
          isDefault ? styles.darkkNavItem : styles.whiteNavItem,
          pathname === href ? styles.listItemActive : styles.listItem,
        ]}
      >
        {title}
      </Box>
    </Link>
  );
};
