"use client";
import { FC, useState, useCallback } from "react";

import { useMediaQuery } from "@mui/material";
import { useUnit } from "effector-react";
import { useTranslations } from "next-intl";

import { AUTH_TITLE, EAuthSteps } from "../../lib/constants";
import { $authStep } from "../../model";
import { styles } from "./styles";

import { AuthStepperDrawer } from "@/features/login/ui/AuthStepperDrawer";
import { Login } from "@/features/login/ui/Login";
import { OTP } from "@/features/login/ui/OTP";
import { SignUp } from "@/features/login/ui/SignUp";
import { Success } from "@/features/login/ui/Success";

import { useCustomTheme } from "@/shared/lib/hooks";
import { closeAuthModal, $isAuthModalOpen } from "@/shared/model/store/auth";
import { SimpleActionModal, SimpleActionContent } from "@/shared/ui";

const StepRenderer: FC = () => {
  const step = useUnit($authStep);

  switch (step) {
    case EAuthSteps.LOGIN:
      return <Login />;
    case EAuthSteps.OTP:
      return <OTP />;
    case EAuthSteps.SIGN_UP:
      return <SignUp />;
    default:
      return <Success />;
  }
};

export const Stepper: FC = () => {
  const t = useTranslations();
  const theme = useCustomTheme();
  const isPhone = useMediaQuery(theme.breakpoints.down("smd"));

  const [step, isAuthModalOpen, closeAuthModalFn] = useUnit([
    $authStep,
    $isAuthModalOpen,
    closeAuthModal,
  ]);

  const [isCancelModalOpen, setCancelModalOpen] = useState(false);

  const handleOpenCancelModal = useCallback(() => {
    if (step === EAuthSteps.SIGN_UP) {
      setCancelModalOpen(true);
      return;
    }

    closeAuthModalFn();
  }, [closeAuthModalFn, step]);

  return (
    <>
      <AuthStepperDrawer
        isPhone={isPhone}
        isOpen={isAuthModalOpen}
        title={t(AUTH_TITLE[step])}
        onClose={handleOpenCancelModal}
        closeButton={step !== EAuthSteps.SUCCESS}
        drawerPaperProps={
          step === EAuthSteps.SUCCESS ? styles.successPaperProps : {}
        }
      >
        <StepRenderer />
      </AuthStepperDrawer>

      <SimpleActionModal
        okText={"YES_CANCEL"}
        isOpen={isCancelModalOpen}
        callback={closeAuthModalFn}
        cancelText={"DO_NOT_CANCEL"}
        onOpen={() => setCancelModalOpen(true)}
        onClose={() => setCancelModalOpen(false)}
        Content={<SimpleActionContent text={`${t("CANCEL_REGISTRATION")}?`} />}
      />
    </>
  );
};
