import { mainTheme } from "shared/ui/styles";

export const styles = {
  alert: {
    marginTop: "16px",
  },
  errorAlert: {
    marginTop: "16px",
  },
  footer: {
    display: "flex",
    flexDirection: "column",
  },
  loginWrapper: {
    display: "flex",
    flexDirection: "column",
  },
  loginFields: {
    gap: "16px",
    display: "flex",
    flexDirection: "column",
  },
  agreement: {
    order: 0,

    [mainTheme.breakpoints.up("sm")]: {
      order: -1,
      marginTop: "48px",
    },
  },
  submitButton: {
    height: "48px",
    margin: "16px 0",

    [mainTheme.breakpoints.up("sm")]: {
      margin: "16px 0 0 0",
    },
  },
  title: {
    marginBottom: "36px",
    color: "grey.content",

    [mainTheme.breakpoints.up("sm")]: {
      marginBottom: "24px",
    },
  },
  contentWrapper: {
    [mainTheme.breakpoints.down("smd")]: {
      height: "100%",
      display: "flex",
      paddingBottom: "16px",
      flexDirection: "column",
      justifyContent: "space-between",
    },
  },
};
