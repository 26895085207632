export const styles = {
  skeleton: {
    width: "50%",
    height: "38px",
  },

  wrapper: {
    display: "flex",
    flexDirection: "column",
  },

  whatsappIcon: {
    width: "30px",
    height: "30px",
    fill: "transparent",
  },

  iconBox: {
    display: "flex",
    maxHeight: "36px",
    alignItems: "center",
  },

  phoneBox: {
    display: "flex",
    columnGap: "12px",
    alignItems: "center",
    flexDirection: "row",
  },

  icon: {
    padding: "4px",
    display: "flex",
    borderRadius: "50%",
    alignItems: "center",
    color: "dominant.white",
    justifyContent: "center",
    backgroundColor: "success.high",
  },

  phone: {
    height: "38px",
    fontWeight: 500,
    display: "flex",
    marginTop: "8px",
    fontSize: "24px",
    transition: "0.2s",
    lineHeight: "28px",
    alignItems: "center",

    "&:hover": {
      opacity: "0.7",
    },
    "&:first-child": {
      marginTop: 0,
    },

    "& a": {
      textDecoration: "none",
      color: "content.secondary",
    },
  },
};
