import { FC, memo, FormEvent } from "react";

import { Box, Typography } from "@mui/material";
import { useForm } from "effector-forms";
import { useUnit } from "effector-react";
import { useTranslations } from "next-intl";

import { $$phoneUserForm } from "../../model/forms";
import { ModalLayout } from "../ModalLayout";
import { styles } from "./styles";

import { sendDataLayerEvent } from "@/shared/model/analytics";
import { sendOTPMutation } from "@/shared/model/api/auth";
import { Agreement, LoaderButton, NewInputMask } from "@/shared/ui";

export const Login: FC = memo(() => {
  const t = useTranslations();

  const { pending: isLoading } = useUnit(sendOTPMutation);
  const { fields, submit } = useForm($$phoneUserForm);
  const sendDataLayerEventFn = useUnit(sendDataLayerEvent);

  const handleSubmit = (event: FormEvent) => {
    event.preventDefault();
    sendDataLayerEventFn({
      event: "getCode",
    });
    submit();
  };

  return (
    <Box sx={styles.loginWrapper}>
      <form onSubmit={handleSubmit}>
        <ModalLayout
          Footer={
            <Box sx={styles.footer}>
              <LoaderButton
                type="submit"
                loading={isLoading}
                variant="contained"
                sx={styles.submitButton}
              >
                {t("GET_CODE")}
              </LoaderButton>

              <Agreement sx={styles.agreement} />
            </Box>
          }
          Content={
            <Box sx={styles.contentWrapper}>
              <Box>
                <Typography sx={styles.title}>
                  {t("LEAVE_PHONE_NUMBER_FOR_REGISTRATION_CODE")}
                </Typography>
                <Box>
                  <Box sx={styles.loginFields}>
                    <NewInputMask
                      type="tel"
                      label={t("PHONE_NUMBER")}
                      mask={"+7 999 999-99-99"}
                      value={fields.phone.value}
                      placeholder="+7 (___) ___-____"
                      onChange={(e) => fields.phone.onChange(e.target.value)}
                    />
                  </Box>
                </Box>
              </Box>
            </Box>
          }
        />
      </form>
    </Box>
  );
});
