import { Divider, useMediaQuery } from "@mui/material";

import { Condition } from "@/shared/lib/condition";
import { useCustomTheme } from "@/shared/lib/hooks";

export const FooterDivider = () => {
  const theme = useCustomTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));

  return (
    <Condition match={!isDesktop}>
      <Divider sx={{ my: "24px" }} />
    </Condition>
  );
};
