import { FC, memo } from "react";

import { styles } from "./styles";

import { DialogWrapper, SwipeableDrawer } from "@/shared/ui";

type TProps = {
  title: string;
  isOpen: boolean;
  isPhone?: boolean;
  onOpen?: () => void;
  onClose: () => void;
  closeButton?: boolean;
  drawerPaperProps?: Record<string, unknown>;
  children: string | JSX.Element | JSX.Element[];
};

export const AuthStepperDrawer: FC<TProps> = memo(
  ({
    title,
    isOpen,
    onClose,
    isPhone,
    children,
    closeButton,
    drawerPaperProps,
    onOpen = () => null,
  }) => {
    if (isPhone) {
      return (
        <SwipeableDrawer
          title={title}
          onOpen={onOpen}
          isOpen={isOpen}
          onClose={onClose}
          closeButton={closeButton}
          paperSx={drawerPaperProps}
        >
          {children}
        </SwipeableDrawer>
      );
    }

    return (
      <DialogWrapper
        open={isOpen}
        title={title}
        onClose={onClose}
        sx={styles.dialog}
        sxTitle={styles.dialogTitle}
      >
        {children}
      </DialogWrapper>
    );
  },
);
