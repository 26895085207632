/* eslint-disable perfectionist/sort-objects */

import { createForm } from "effector-forms";

import {
  PHONE_REGEX,
  EMAIL_REGEX,
  REQUIRED_FIELD_ERROR,
  CORRECT_PHONE_ERROR_TEXT,
  CORRECT_EMAIL_ERROR_TEXT,
} from "@/shared/lib/constants";
import { checkValidationRule } from "@/shared/lib/helpers";

type TRegisterUserForm = {
  email: string;
  last_name: string;
  first_name: string;
};

export const $$phoneUserForm = createForm<{ phone: string }>({
  fields: {
    phone: {
      init: "",
      rules: [
        {
          name: "required",
          validator: (value: string) =>
            Boolean(value) && checkValidationRule(value, PHONE_REGEX),
          errorText: CORRECT_PHONE_ERROR_TEXT,
        },
      ],
    },
  },
  validateOn: ["submit"],
});

export const $$otpUserForm = createForm<{ code: string }>({
  fields: {
    code: {
      init: "",
      rules: [
        {
          name: "required",
          validator: (value: string) => value.length === 4,
        },
      ],
    },
  },
  validateOn: ["submit"],
});

export const $$registerUserForm = createForm<TRegisterUserForm>({
  fields: {
    first_name: {
      init: "",
      rules: [
        {
          name: "required",
          errorText: REQUIRED_FIELD_ERROR,
          validator: (value: string) => Boolean(value),
        },
      ],
    },

    last_name: {
      init: "",
      rules: [
        {
          name: "required",
          errorText: REQUIRED_FIELD_ERROR,
          validator: (value: string) => Boolean(value),
        },
      ],
    },

    email: {
      init: "",
      rules: [
        {
          name: "required",
          validator: (value: string) => EMAIL_REGEX.test(value),
          errorText: CORRECT_EMAIL_ERROR_TEXT,
        },
      ],
    },
  },
  validateOn: ["submit"],
});
