import { mainTheme } from "shared/ui/styles";

export const styles = {
  divider: {
    marginX: "-16px",
  },

  footer: {
    marginBottom: "20px",

    [mainTheme.breakpoints.up("sm")]: {
      marginBottom: "0",
    },
  },

  content: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },

  wrapper: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",

    [mainTheme.breakpoints.down("smd")]: {
      minHeight: "calc(100dvh - 82px)",
    },
  },
};
