export const styles = {
  dialogTitle: {
    padding: "24px",
  },

  dialog: {
    width: "422px",
    padding: "24px",
  },
};
