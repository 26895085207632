export const styles = {
  phoneContainer: {
    marginRight: "16px",
  },

  default: {
    backgroundColor: "dominant.white",
  },

  profileContainer: {
    display: "flex",
    alignItems: "center",
  },

  transparent: {
    background: "none",
    backgroundColor: "transparent",
  },

  headerWrapper: {
    padding: "16px",
    position: "relative",
    borderRadius: "0 0 16px 16px",
  },

  container: {
    zIndex: 5,
    display: "flex",
    position: "relative",
    color: "dominant.white",
    justifyContent: "space-between",
  },

  logoWrapper: {
    display: "flex",
    maxWidth: "125px",
    flexDirection: "column",
    justifyContent: "center",

    "& a": {
      color: "primary.main",
    },
  },
};
