import { TSearchData, BACKEND_KEYS } from "../../common";

export type TNavDeparture = {
  id: string;
  code: string;
  name: string;
  promoted: boolean;
  departure_location: null | string;
};

export type TMenuElement = {
  id: string;
  price: number;
  tags?: string[];
  location: string;
  country?: string;
  imageSrc?: string;
  link: TSearchData;
};

export type Country = {
  id: string;
  name: string;
};

export type THeaderLocation = {
  id: string;
  code: string;
  name: string;
  price: number;
  cover?: string;
};

export type TArrivalLocation = THeaderLocation & {
  locations: THeaderLocation[];
};

export type TDepartureLocation = THeaderLocation & {
  promoted?: boolean;
  country: THeaderLocation;
  [BACKEND_KEYS.departure]?: Country;
};

export type TDefaultDeparture = {
  id: string;
  name: string;
  code: string;
  promoted: boolean;
  departure_location: string;
};

export type TTourChild = {
  id: string;
  name: string;
  link: string;
  date: string;
  price: number;
  cover: string;
  tags?: string[];
  currency: string;
  country: Country;
  price_pax?: number;
  link_params: TSearchData;
  code: "country" | "location";
  arrival_country: TArrivalLocation;
  arrival_location?: THeaderLocation;
};

export type PopoverContentResultData = {
  id: string;
  link?: string;
  title: string;
  children?: TTourChild[];
  code: "all_tours" | "hot_tours";
};

export type MenuRootObject = {
  success: boolean;
  data: PopoverContentResultData[];
};

export enum EPopupType {
  AllTours = "allTours",
  HotTours = "hotTours",
}

export enum ETourType {
  Country = "country",
  Location = "location",
}

export enum ETourLabel {
  Country = "По странам",
  Location = "По регионам",
}

export type TCardId = {
  card: TMenuElement;
  tourType?: ETourType;
  popupType: EPopupType;
};
