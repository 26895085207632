import { mainTheme } from "shared/ui/styles";

export const styles = {
  alert: {
    marginTop: "16px",
  },
  errorAlert: {
    marginTop: "16px",
  },
  titleWrapper: {
    gap: "8px",
    display: "grid",
  },
  phoneNumber: {
    fontSize: "18px",
    fontWeight: "bold",
  },
  submitButton: {
    height: "48px",
    margin: "16px 0 0 0",
  },
  loginWrapper: {
    display: "flex",
    flexDirection: "column",
  },
  changeNumberButton: {
    cursor: "pointer",
    color: "accent.brand.primary",
  },
  inputWrapper: {
    mt: "36px",

    [mainTheme.breakpoints.up("sm")]: {
      mb: "32px",
    },
  },
  phoneNumberWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  inputLabel: {
    mb: "12px",
    fontSize: "14px",
    color: "content.grey-400",

    [mainTheme.breakpoints.up("sm")]: {
      fontSize: "16px",
      color: "content.secondary",
    },
  },
  contentWrapper: {
    [mainTheme.breakpoints.down("smd")]: {
      height: "100%",
      display: "flex",
      paddingBottom: "16px",
      flexDirection: "column",
      justifyContent: "space-between",
    },
  },
};
