export const styles = {
  item: {
    padding: 0,
    margin: "0 10px",
  },

  button: {
    padding: 0,
    fontSize: "16px",
    minWidth: "45px",
  },

  link: {
    width: "100%",
    padding: "12px",
    color: "#04172C",

    "&:hover": {
      color: "red",
    },
  },
};
