"use client";
import { reflect } from "@effector/reflect";

import { PhoneLinks } from "./PhoneLinks";

import { $contactsData } from "@/entities/footer/model";

import { EContactTypes } from "@/shared/model/types/contacts";

export const PhoneLinksView = reflect({
  view: PhoneLinks,
  bind: {
    phones: $contactsData.map((contacts) =>
      contacts.filter((c) => c.type === EContactTypes.Phone),
    ),
  },
});
