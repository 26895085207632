"use client";
import Image from "next/image";
import { FC } from "react";

import { Box, Typography } from "@mui/material";
import { useUnit } from "effector-react";
import { useLocale, useTranslations } from "next-intl";

import { PhoneLinksView } from "../PhoneLinks";
import { SocialLinksView } from "../SocialLinks";
import { FooterDivider } from "./FooterDivider";
import { LinkItem } from "./LinkItem";
import { List, styles, ColumnTitle } from "./styles";

import {
  ABOUT_LINKS,
  ASTANAHUB_DATA,
  CUSTOMERS_LINKS,
  AGREEMENT_LINKS,
  PAYMENT_SYSTEMS_IMAGES,
} from "@/entities/footer/lib";

import { sendDataLayerEvent } from "@/shared/model/analytics";
import { Link, Container, AppDownloadButtons } from "@/shared/ui";

const ContinuingUseAcceptTerms: FC<{
  isRuLocale: boolean;
  children: JSX.Element;
}> = ({ children, isRuLocale }) => {
  if (isRuLocale) {
    return (
      <>Продолжая использовать наш сайт, вы принимаете условия {children}.</>
    );
  }

  return (
    <>
      Біздің сайтты пайдалануды жалғастыра отырып, сіз {children} шарттарын
      қабылдайсыз.
    </>
  );
};

export const Footer = () => {
  const sendDataLayerEventFn = useUnit(sendDataLayerEvent);
  const t = useTranslations();
  const locale = useLocale();

  return (
    <Box component="footer" sx={styles.footer}>
      <Container>
        <Box sx={styles.footerTop}>
          <Box sx={styles.callCenter}>
            <Typography sx={styles.callCenterTitle}>
              {t("CALL_CENTER")}
            </Typography>
            <PhoneLinksView />
            <Typography sx={styles.callCenterSchedule}>
              {t("WORKING_HOURS")}
            </Typography>
            <Box sx={styles.callCenterApps}>
              <Typography sx={styles.callCenterApps.text}>
                {t("ALL_TOURS_ONE_APP")}
              </Typography>
              <AppDownloadButtons />
            </Box>
          </Box>

          <Box sx={styles.navigation}>
            <Box sx={styles.socialBlock}>
              <ColumnTitle sx={{ textAlign: "right" }}>
                {t("FOLLOW_US")}
              </ColumnTitle>
              <SocialLinksView />
            </Box>
            <Box sx={styles.navForCustomers}>
              <ColumnTitle>{t("CUSTOMERS")}</ColumnTitle>
              <List>
                {CUSTOMERS_LINKS.map(({ name, link }) => (
                  <Box key={name} component="nav">
                    <Link
                      href={link}
                      onClick={() =>
                        sendDataLayerEventFn({
                          event: "bayersDetailClick",
                          data: {
                            clickUrl: link,
                            clickText: name,
                          },
                        })
                      }
                    >
                      <Typography sx={styles.navForCustomers.item}>
                        {t(name)}
                      </Typography>
                    </Link>
                  </Box>
                ))}
              </List>
            </Box>

            <Box sx={styles.navAbout}>
              <ColumnTitle>{t("ABOUT_COMPANY")}</ColumnTitle>
              <List>
                {ABOUT_LINKS.map(({ name, link }) => (
                  <Box key={name} component="nav">
                    <Link
                      href={link}
                      onClick={() =>
                        sendDataLayerEventFn({
                          event: "companyDetailClick",
                          data: {
                            clickUrl: link,
                            clickText: name,
                          },
                        })
                      }
                    >
                      <Typography sx={styles.navForCustomers.item}>
                        {t(name)}
                      </Typography>
                    </Link>
                  </Box>
                ))}
              </List>
            </Box>

            <Box sx={styles.paymentAndAstanaHubBlock}>
              <Box sx={styles.astanaHubBlock}>
                <Typography sx={styles.lightGrayTitle}>
                  {t("TECHNOPARK_RESIDENT")}
                </Typography>
                <Box sx={styles.astanaHubBlock.icon}>
                  <Link type="a" target="_blank" href={ASTANAHUB_DATA.link}>
                    {ASTANAHUB_DATA.icon}
                  </Link>
                </Box>
              </Box>
              <Box sx={styles.paymentSystemsBlock}>
                <Typography sx={styles.lightGrayTitle}>
                  {t("WE_ACCEPT")}
                </Typography>
                <Box sx={styles.paymentLogosWrapper}>
                  {PAYMENT_SYSTEMS_IMAGES.map(
                    ({ width, height, imageSource }, idx) => (
                      <Image
                        key={idx}
                        width={width}
                        height={height}
                        src={imageSource}
                        alt="payment system"
                        style={{
                          height: "auto",
                          maxWidth: "100%",
                        }}
                      />
                    ),
                  )}
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>

        <FooterDivider />

        <Box sx={styles.footerBottom}>
          {Object.values(AGREEMENT_LINKS).map(({ name, link }) => (
            <Box key={name} component="nav">
              <LinkItem link={link} name={t(name)} />
            </Box>
          ))}
          <Typography>{t("ALL_RIGHTS_RESERVED")}</Typography>
        </Box>

        <FooterDivider />

        <Box sx={[styles.footerBottom, styles.policy]}>
          <Typography>
            {t("TO_IMPROVE_SITE_USABILITY")}{" "}
            <LinkItem
              isUnderlined
              name={t("TOUR_USES_COOKIES")}
              link={AGREEMENT_LINKS.userAgreement.link}
            />{" "}
            <ContinuingUseAcceptTerms isRuLocale={locale === "ru"}>
              <LinkItem
                isUnderlined
                name={t("USER_AGREEMENT_RP")}
                link={AGREEMENT_LINKS.userAgreement.link}
              />
            </ContinuingUseAcceptTerms>{" "}
            {t("IF_DONT_WANT_DATA_PROCESSED_DISABLE_BROWSER")}
          </Typography>
        </Box>
      </Container>
    </Box>
  );
};
