export const styles = {
  transparent: {
    top: 0,
    left: 0,
    right: 0,
    zIndex: 10,
    padding: "24px 0",

    position: "absolute",
    borderRadius: "0 0 16px 16px",
  },

  default: {
    zIndex: 10,
    padding: "24px 0",

    background: "white",
    borderRadius: "0 0 16px 16px",
    boxShadow: "0px 1px 0px rgba(164, 179, 190, 0.25)",
    "& hr": {
      width: "100%",
      border: "none",
      margin: "12px 0",
      borderTop: "1px solid rgba(255, 255, 255, 0.2)",
    },
  },
};
