import { FC } from "react";

import { WhatsApp } from "@mui/icons-material";
import { Box, Typography } from "@mui/material";

import { styles } from "./styles";

import { TNormalizedContact } from "@/shared/model/types/contacts";
import { Link } from "@/shared/ui";

type TProps = {
  onClick: () => void;
} & TNormalizedContact;

export const PhoneItem: FC<TProps> = ({
  onClick,
  link = "",
  value = "",
  isWhatsApp,
}) => {
  if (isWhatsApp) {
    return (
      <Box sx={styles.phoneBox}>
        <Typography sx={styles.phone}>
          <a href={link} onClick={onClick}>
            {value}
          </a>
        </Typography>
        <Link
          type="a"
          href={link}
          target="_blank"
          onClick={onClick}
          style={styles.iconBox}
        >
          <Box sx={styles.icon}>
            <WhatsApp fontSize="small" />
          </Box>
        </Link>
      </Box>
    );
  }

  return (
    <Typography sx={styles.phone}>
      <a onClick={onClick} href={`tel:${value}`}>
        {value}
      </a>
    </Typography>
  );
};
