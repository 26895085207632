export const styles = {
  skeleton: {
    width: "36px",
    height: "36px",
  },

  wrapper: {
    padding: 0,
    gap: "16px",
    display: "flex",
    flexWrap: "wrap",
    marginTop: "16px",
    listStyleType: "none",

    "& a": {
      color: "grey.dark",
      transition: "0.2s",

      "&:hover": {
        opacity: "0.7",
      },
    },
  },
};
