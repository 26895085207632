import { ETourType, ETourLabel } from "@/shared/model/types/header-menu";

export const STATIC_LINKS = [
  {
    href: "/tours",
    title: "TOURS_BY_DESTINATIONS",
    id: "navbar_toursByDestination_test",
  },
  {
    href: "/o-kompanii",
    event: "aboutCompany",
    title: "ABOUT_COMPANY",
    id: "navbar_aboutCompany_test",
  },
  {
    href: "/kontakty",
    title: "CONTACTS",
    id: "navbar_contacts_test",
    event: "headerContactsAndOffices",
  },
  {
    href: "/visa",
    title: "VISA",
    event: "headerVisa",
    id: "navbar_visa_test",
  },
];

export const CORPORATE_LINK = {
  id: "navbar_corporate_test",
  title: "FOR_CORPORATE_CLIENTS",
  href: "https://corporate.kaztour.kz/",
};

export const LANG_OPTIONS = [
  {
    code: "ru",
    name: "Русский",
  },
  {
    code: "kz",
    name: "Қазақша",
  },
];

export const ALL_TOURS_POPUP_TABS = [
  {
    value: ETourType.Country,
    label: ETourLabel.Country,
  },
  {
    value: ETourType.Location,
    label: ETourLabel.Location,
  },
];

export const AMOUNT_OF_ALL_TOURS_CARDS = 4;
export const AMOUNT_OF_HOT_TOURS_CARDS = 8;

export const DOTS_MENU_ANCHOR_ORIGIN = {
  vertical: 70,
  horizontal: 545,
};
