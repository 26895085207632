import { FC, memo, useRef } from "react";

import { Box } from "@mui/material";
import { useUnit } from "effector-react";
import { useTranslations } from "next-intl";
import useResizeObserver from "use-resize-observer";

import { MenuLink } from "../MenuLink";
import { MenuWrapper } from "../MenuWrapper";
import { styles } from "./styles";

import { QuestionnaireButton } from "@/entities/questionnaire";

import { sendDataLayerEvent } from "@/shared/model/analytics";
import { Container, KazTourLogoLink } from "@/shared/ui";

type TProps = {
  isDefault: boolean;
  HeaderActions: JSX.Element;
};

export const DesktopMenu: FC<TProps> = memo(({ isDefault, HeaderActions }) => {
  const t = useTranslations();
  const sendDataLayerEventFn = useUnit(sendDataLayerEvent);
  const headerRef = useRef<HTMLDivElement>(null);
  const { ref: topHeader } = useResizeObserver();

  return (
    <MenuWrapper isDefault={isDefault}>
      <Container sx={styles.container}>
        <Box ref={topHeader}>
          <div ref={headerRef} style={styles.button}>
            <Box sx={styles.navigation}>
              <Box sx={styles.logo}>
                <KazTourLogoLink isHomePage={!isDefault} />
              </Box>

              <nav aria-label="primary navigation">
                <Box component="ul" sx={styles.navlist}>
                  <MenuLink
                    href={"/tours"}
                    title={t("TOURS")}
                    isDefault={isDefault}
                    onClick={() =>
                      sendDataLayerEventFn({
                        event: "toursByDestination",
                      })
                    }
                  />

                  <MenuLink
                    isDefault={isDefault}
                    title={t("HOT_TOURS")}
                    href={"/s/hot-tours/all"}
                    id="navbar_hotTours_test"
                    onClick={() => {
                      sendDataLayerEventFn({
                        event: "hotToursDropDownMenu",
                      });
                    }}
                  />

                  <QuestionnaireButton
                    sx={{
                      ...styles.questionnaire,
                      color: isDefault ? "content.primary" : "dominant.white",
                    }}
                  />
                </Box>
              </nav>
            </Box>

            {HeaderActions}
          </div>
        </Box>
      </Container>
    </MenuWrapper>
  );
});
