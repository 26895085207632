import { FC, useMemo } from "react";

import { useUnit } from "effector-react";

import { capitalizeFirstLetter } from "@/shared/lib/helpers";
import { TDataLayerEvent, sendDataLayerEvent } from "@/shared/model/analytics";
import {
  EContactTypes,
  TNormalizedContact,
} from "@/shared/model/types/contacts";

import FacebookIcon from "@/public/icons/socials/facebook.svg";
import InstagramIcon from "@/public/icons/socials/instagram.svg";
import TelegramIcon from "@/public/icons/socials/telegram.svg";
import YouTubeIcon from "@/public/icons/socials/youtube.svg";

type TProps = {
  onClick?: () => void;
} & TNormalizedContact;

const getSocialIcon = (type: EContactTypes) => {
  switch (type) {
    case EContactTypes.Instagram:
      return <InstagramIcon />;
    case EContactTypes.Facebook:
      return <FacebookIcon />;
    case EContactTypes.Telegram:
      return <TelegramIcon />;
    case EContactTypes.Youtube:
      return <YouTubeIcon />;
    default:
      return <></>;
  }
};

export const SocialItem: FC<TProps> = ({ type, value }) => {
  const icon = useMemo(() => getSocialIcon(type), [type]);

  const sendDataLayerEventFn = useUnit(sendDataLayerEvent);

  return (
    <a
      href={value}
      target="_blank"
      rel="noreferrer"
      onClick={() =>
        sendDataLayerEventFn({
          event: `crossing${capitalizeFirstLetter(type)}` as TDataLayerEvent,
          data: {
            clickUrl: value,
            clickText: type,
          },
        })
      }
    >
      {icon}
    </a>
  );
};
