import(/* webpackMode: "eager" */ "/app/entities/footer/ui/Footer/Footer/Footer.tsx");
import(/* webpackMode: "eager" */ "/app/features/navbar/ui/Navbar/Navbar.tsx");
import(/* webpackMode: "eager" */ "/app/node_modules/@effector/next/dist/index.js");
import(/* webpackMode: "eager" */ "/app/node_modules/next-intl/dist/esm/shared/NextIntlClientProvider.js");
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/script.js");
import(/* webpackMode: "eager" */ "/app/public/icons/socials/facebook_colored.svg");
import(/* webpackMode: "eager" */ "/app/public/icons/socials/instagram_colored.svg");
import(/* webpackMode: "eager" */ "/app/public/icons/socials/telegram_colored.svg");
import(/* webpackMode: "eager" */ "/app/public/icons/socials/whatsapp_colored.svg");
import(/* webpackMode: "eager" */ "/app/shared/config/scripts/amplitude-init.ts");
import(/* webpackMode: "eager" */ "/app/shared/config/scripts/mindbox-scripts.tsx");
import(/* webpackMode: "eager" */ "/app/shared/ui/index.ts");
import(/* webpackMode: "eager" */ "/app/shared/ui/styles/theme/ThemeRegistry.tsx");
import(/* webpackMode: "eager" */ "/app/widgets/global-modals/ui/renderer.tsx")