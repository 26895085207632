export const styles = {
  button: {
    padding: 0,
    fontSize: "16px",
    minWidth: "45px",
  },

  item: {
    padding: "12px",
    margin: "0 10px",
    minWidth: "130px",
    transition: "0.2s",
    border: "1px solid",
    borderRadius: "4px",
    borderColor: "transparent",

    "&:hover": {
      backgroundColor: "unset",
      borderColor: "primary.main",
    },
  },
};
