"use client";

import { FC } from "react";

import { SxProps, useMediaQuery } from "@mui/material";
import { useUnit } from "effector-react";
import { useTranslations } from "next-intl";

import { styles } from "./styles";

import { useCustomTheme } from "@/shared/lib/hooks/useCustomTheme";
import { sendDataLayerEvent } from "@/shared/model/analytics";
import {
  $defaultDeparture,
  closeDepartureModal,
  $promotedDepartures,
  setDefaultDeparture,
  $isDepartureModalOpen,
} from "@/shared/model/store/default-departure";
import { TNavDeparture } from "@/shared/model/types/header-menu";
import { List, ListItem, DialogWrapper } from "@/shared/ui";

type TProps = {
  isModal?: boolean;
  wrapperSx?: SxProps;
  onSelectCallback?: () => void;
};

type TListProps = {
  wrapperSx: SxProps;
  onClick: (v: TNavDeparture) => void;
};

const DepartureLayout: FC<TListProps> = ({ onClick, wrapperSx }) => {
  const [orderedList, defaultDeparture] = useUnit([
    $promotedDepartures,
    $defaultDeparture,
  ]);

  return (
    <List sx={{ ...styles.departuresList, ...wrapperSx }}>
      {orderedList.map((item) => (
        <ListItem
          key={item.id}
          sx={styles.listItem}
          onClick={() => onClick(item)}
          id={`departure_${item.code}_test`}
          selected={defaultDeparture?.code === item?.code}
        >
          <span>{item?.name}</span>
        </ListItem>
      ))}
    </List>
  );
};

export const DepartureList: FC<TProps> = ({
  wrapperSx = {},
  isModal = false,
  onSelectCallback,
}) => {
  const t = useTranslations();
  const theme = useCustomTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const sendDataLayerEventFn = useUnit(sendDataLayerEvent);

  const [isOpen, closeDepartureModalFn, setDefaultDepartureFn] = useUnit([
    $isDepartureModalOpen,
    closeDepartureModal,
    setDefaultDeparture,
  ]);

  const handleCloseModal = () => {
    onSelectCallback?.();
    closeDepartureModalFn();
  };

  const handleClick = (item: TNavDeparture) => {
    sendDataLayerEventFn({
      event: "locationClick",
      data: {
        clickText: item?.name,
      },
    });

    setDefaultDepartureFn(item);
    handleCloseModal();
  };

  if (isModal) {
    return (
      <DialogWrapper
        open={isOpen}
        title={t("SELECT_CITY")}
        onClose={handleCloseModal}
        closeButtonId="close_departures_modal_test"
        sx={{ width: isMobile ? undefined : "422px" }}
      >
        <DepartureLayout wrapperSx={wrapperSx} onClick={handleClick} />
      </DialogWrapper>
    );
  }

  return <DepartureLayout wrapperSx={wrapperSx} onClick={handleClick} />;
};
