/* eslint-disable perfectionist/sort-objects */
import * as amplitude from "@amplitude/analytics-browser";
import {
  sample,
  createStore,
  createEvent,
  createEffect,
  EventCallable,
} from "effector";

import { EAuthSteps } from "../lib/constants";
import { $$otpUserForm, $$phoneUserForm, $$registerUserForm } from "./forms";

import { COUNTDOWN_TIME } from "@/shared/lib/constants";
import { TDataLayerEvent, sendDataLayerEvent } from "@/shared/model/analytics";
import {
  signUpMutation,
  logoutMutation,
  sendOTPMutation,
  confirmOTPMutation,
} from "@/shared/model/api/auth";
import { closeAuthModal } from "@/shared/model/store/auth";

export const $otpTime = createStore(Date.now() + COUNTDOWN_TIME);
export const $otpIndex = createStore(0);
export const $authStep = createStore(EAuthSteps.LOGIN);
export const $currentPhone = createStore("");

export const resendOTP = createEvent();
export const returnToPhoneStep = createEvent();
export const goToSuccessStep = createEvent();

const setAmplitudeUserFx = createEffect((userId: string) => {
  amplitude.setUserId(userId);
});

sample({
  clock: $$phoneUserForm.formValidated,
  target: sendOTPMutation.start,
});

sample({
  clock: resendOTP,
  source: $currentPhone,
  fn: (phone) => ({
    phone,
  }),
  target: sendOTPMutation.start,
});

sample({
  clock: $$phoneUserForm.formValidated,
  fn: ({ phone }) => phone,
  target: $currentPhone,
});

sample({
  clock: sendOTPMutation.finished.success,
  fn: () => EAuthSteps.OTP,
  target: $authStep,
});

sample({
  clock: $$otpUserForm.formValidated,
  source: $currentPhone,
  fn: (phone, { code }) => ({ code, phone }),
  target: confirmOTPMutation.start,
});

sample({
  clock: confirmOTPMutation.finished.success,
  fn: ({ result }) => {
    if (result.data.data.finished) {
      return EAuthSteps.SUCCESS;
    }

    return EAuthSteps.SIGN_UP;
  },
  target: $authStep,
});

sample({
  clock: confirmOTPMutation.finished.success,
  filter: ({ result }) => result.data.data.finished,
  target: closeAuthModal,
});

sample({
  clock: confirmOTPMutation.finished.success,
  fn: () => ({
    event: "signInSuccess" as TDataLayerEvent,
  }),
  target: [sendDataLayerEvent, setAmplitudeUserFx],
});

sample({
  clock: $$registerUserForm.formValidated,
  source: $currentPhone,
  fn: (phone, formValues) => ({
    phone,
    ...formValues,
  }),
  target: signUpMutation.start,
});

sample({
  clock: signUpMutation.finished.success,
  target: goToSuccessStep,
});

sample({
  clock: [closeAuthModal, logoutMutation.finished.success],
  target: [
    $$phoneUserForm.reset,
    $$otpUserForm.reset,
    $$registerUserForm.reset,
  ] as EventCallable<void>[],
});

sample({
  clock: logoutMutation.finished.success,
  fn: () => EAuthSteps.LOGIN,
  target: $authStep,
});

sample({
  clock: closeAuthModal,
  source: $authStep,
  filter: (step) => step !== EAuthSteps.OTP,
  fn: () => EAuthSteps.LOGIN,
  target: $authStep,
});

$authStep.reset(closeAuthModal);
$authStep.on(returnToPhoneStep, () => EAuthSteps.LOGIN);
$authStep.on(goToSuccessStep, () => EAuthSteps.SUCCESS);

$currentPhone.reset(returnToPhoneStep);
$currentPhone.reset(signUpMutation.finished.success);

$otpTime.on(
  [resendOTP, $$phoneUserForm.formValidated],
  () => Date.now() + COUNTDOWN_TIME,
);
$otpIndex.on([resendOTP, $$phoneUserForm.formValidated], (store) => store + 1);
