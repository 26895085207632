import dynamic from "next/dynamic";
import { usePathname } from "next/navigation";
import { FC } from "react";

import { Box } from "@mui/material";

import { Loader } from "./Loader";
import { styles } from "./styles";

import { Condition } from "@/shared/lib/condition";
import { KazTourLogoLink, HeaderPhoneNumber } from "@/shared/ui";

const ProfileMenu = dynamic(() => import("../ProfileMenu"), {
  ssr: false,
  loading: () => <Loader />,
});

const LinksMenu = dynamic(() => import("../LinksMenu"), {
  ssr: false,
  loading: () => <Loader withMargin />,
});

type TProps = {
  isDefault?: boolean;
};

const DEFAULT_STYLES = {
  ...styles.default,
  ...styles.headerWrapper,
};

const HOME_PAGE_STYLES = {
  ...styles.transparent,
  ...styles.headerWrapper,
};

export const MobileHeaderMenu: FC<TProps> = ({ isDefault }) => {
  const pathname = usePathname() ?? "";

  const isSearchPage = pathname.includes("/search");
  const isHotelsPage = pathname.includes("/oteli");

  const roundedStyles =
    isSearchPage || isHotelsPage ? { borderRadius: "none" } : {};

  return (
    <Box
      component="header"
      sx={
        isDefault ? { ...DEFAULT_STYLES, ...roundedStyles } : HOME_PAGE_STYLES
      }
    >
      <Box sx={styles.container}>
        <Box sx={styles.logoWrapper}>
          <KazTourLogoLink isHomePage={!isDefault} />
        </Box>

        <Box sx={styles.profileContainer}>
          <Condition match={Boolean(!isDefault)}>
            <Box sx={styles.phoneContainer}>
              <HeaderPhoneNumber isPhone />
            </Box>
          </Condition>

          <ProfileMenu />
          <LinksMenu isDefault={isDefault} />
        </Box>
      </Box>
    </Box>
  );
};
