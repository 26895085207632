import { FC, MouseEvent } from "react";

import { Menu, MenuItem } from "@mui/material";
import { useUnit } from "effector-react";
import { useTranslations } from "next-intl";

import { $isOpen, $anchorEl, setAnchorEl, resetAnchorEl } from "./model";
import { styles } from "./styles";

import { STATIC_LINKS, CORPORATE_LINK } from "@/features/navbar/lib/constants";

import { TDataLayerEvent, sendDataLayerEvent } from "@/shared/model/analytics";
import { Link, CollapseButton } from "@/shared/ui";

type TProps = {
  isDefaultColor?: boolean;
};

export const LinksMenu: FC<TProps> = ({ isDefaultColor }) => {
  const t = useTranslations();
  const sendDataLayerEventFn = useUnit(sendDataLayerEvent);
  const [anchorEl, isOpen, resetAnchorElFn, setAnchorElFn] = useUnit([
    $anchorEl,
    $isOpen,
    resetAnchorEl,
    setAnchorEl,
  ]);

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorElFn(event.currentTarget);
    sendDataLayerEventFn({
      event: "forClientDropDownMenu",
    });
  };

  const handleClose = () => {
    resetAnchorElFn();
  };

  return (
    <div>
      <CollapseButton
        isOpen={isOpen}
        id="basic-button"
        aria-haspopup="true"
        onClick={handleClick}
        aria-expanded={isOpen ? "true" : undefined}
        aria-controls={isOpen ? "basic-menu" : undefined}
        sx={{
          ...styles.button,
          color: isDefaultColor ? "content.primary" : "dominant.white",
        }}
      >
        {t("FOR_CLIENTS")}
      </CollapseButton>

      <Menu
        open={isOpen}
        id="basic-menu"
        anchorEl={anchorEl}
        onClose={() => handleClose()}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        {STATIC_LINKS.map(({ id, href, title, event }) => (
          <MenuItem
            id={id}
            key={id}
            sx={styles.item}
            onClick={() => {
              handleClose();

              sendDataLayerEventFn({
                event: event as TDataLayerEvent,
              });
            }}
          >
            <Link href={href} style={styles.link}>
              {t(title)}
            </Link>
          </MenuItem>
        ))}

        <MenuItem
          sx={styles.item}
          id={CORPORATE_LINK.id}
          onClick={() => {
            sendDataLayerEventFn({
              event: "toCorporatePersons",
            });

            handleClose();
          }}
        >
          <Link
            type="a"
            target="_blank"
            style={styles.link}
            href={CORPORATE_LINK.href}
          >
            {t(CORPORATE_LINK.title)}
          </Link>
        </MenuItem>
      </Menu>
    </div>
  );
};
