import { FC } from "react";

import { Box } from "@mui/material";
import { useUnit } from "effector-react";

import { PhoneItem } from "./PhoneItem";
import { styles } from "./styles";

import { sendDataLayerEvent } from "@/shared/model/analytics";
import {
  TContact,
  TBackendWA,
  EContactTypes,
} from "@/shared/model/types/contacts";

type TProps = {
  phones: (TContact & TBackendWA)[];
};

export const PhoneLinks: FC<TProps> = ({ phones }) => {
  const sendDataLayerEventFn = useUnit(sendDataLayerEvent);

  const handlePhoneClick = (isWhatsApp?: boolean) => {
    try {
      if (isWhatsApp) {
        sendDataLayerEventFn({
          event: "whatsApp",
        });
      } else {
        sendDataLayerEventFn({
          event: "call",
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Box sx={styles.wrapper}>
      {phones?.map(({ id, link, value, is_whatsapp }) => (
        <PhoneItem
          id={id}
          key={id}
          link={link}
          value={value}
          isWhatsApp={is_whatsapp}
          type={EContactTypes.Phone}
          onClick={() => handlePhoneClick(is_whatsapp)}
        />
      ))}
    </Box>
  );
};
