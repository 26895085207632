import { FC } from "react";

import { Skeleton } from "@mui/material";

type TProps = {
  withMargin?: boolean;
};

export const Loader: FC<TProps> = ({ withMargin }) => {
  return (
    <Skeleton
      width="32px"
      height="32px"
      variant="rounded"
      sx={{ marginLeft: withMargin ? "16px" : 0 }}
    />
  );
};
