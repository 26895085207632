export const styles = {
  closeButton: {
    margin: "0",
  },

  text: {
    padding: "0 16px",
    textAlign: "center",
  },

  title: {
    fontWeight: 500,
    fontSize: "26px",
    textAlign: "center",
  },

  wrapper: {
    gap: "24px",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
  },

  content: {
    gap: "16px",
    display: "flex",
    marginTop: "12px",
    alignItems: "center",
    flexDirection: "column",
  },
};
