import { FC, memo } from "react";

import { Box, Button, Typography } from "@mui/material";
import { useUnit } from "effector-react";
import { useTranslations } from "next-intl";

import { styles } from "./styles";

import { closeAuthModal } from "@/shared/model/store/auth";
import { CircleStatusIcon } from "@/shared/ui";

export const Success: FC = memo(() => {
  const t = useTranslations();
  const closeAuthModalFn = useUnit(closeAuthModal);

  return (
    <Box sx={styles.wrapper}>
      <Box sx={styles.content}>
        <CircleStatusIcon />

        <Typography component="span" sx={styles.title}>
          {t("DATA_SAVED_2")} <br /> {t("IN_YOUR_ACCOUNT")}
        </Typography>

        <Typography sx={styles.text}>
          {t("FULL_TOUR_INFORMATION_WILL_BE_DISPLAYED_THERE_AFTER_BOOKING")}
        </Typography>
      </Box>

      <Button
        fullWidth
        variant="contained"
        sx={styles.closeButton}
        onClick={closeAuthModalFn}
      >
        {t("CONTINUE")}
      </Button>
    </Box>
  );
});
