export const styles = {
  errorAlert: {
    marginTop: "16px",
  },
  loginFooterText: {
    textAlign: "center",
  },
  loginFields: {
    gap: "16px",
    display: "flex",
    flexDirection: "column",
  },
  successPaperProps: {
    height: "unset",
    paddingBottom: "20px",
    borderRadius: "16px 16px 0 0",
  },

  loginWrapper: {
    display: "flex",
    flexDirection: "column",
    "& button": {
      width: "100%",
      margin: "24px 0",
    },

    "@media screen and (min-width: 600px)": {
      minWidth: "374px",
    },
    passwordField: {
      display: "flex",
      justifyContent: "space-between",
    },
  },
};
